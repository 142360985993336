import React, { FC } from 'react';

import Box from '@mui/material/Box';

import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

interface Props {
  name: string;
  health: number;
}

export const MostCriticalNameCell: FC<Props> = ({ name, health }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    }}
  >
    <Box mr={2} sx={{ width: '24px', height: '24px' }}>
      {health > 60 ? (
        <CheckCircleOutlineIcon color="success" />
      ) : (
        <WarningIcon color={health > 30 ? 'warning' : 'error'} />
      )}
    </Box>

    <Box>{name}</Box>
  </Box>
);
