import React from 'react';

import { Column } from 'components/BasicTable/BasicTable';
import { HealthScoreChip } from 'components/HealthScoreChip';
import { MostCriticalNameCell } from 'components/MostCriticalNameCell';

import { Detail } from 'types/detail';

export const columns: Column<Detail>[] = [
  {
    id: 'name',
    label: 'tableHeader.mostCriticalHealth',
    minWidth: 200,
    format: ({ label, health }) => (
      <MostCriticalNameCell name={label} health={health || 0} />
    ),
  },
  {
    id: 'healthCore',
    label: 'tableHeader.emptyString',
    align: 'right',
    minWidth: 120,
    format: ({ health }) => <HealthScoreChip score={health || 0} />,
  },
];
