import React, { FC } from 'react';
import Chip from '@mui/material/Chip';

interface Props {
  score: number | string;
}

export const HealthScoreChip: FC<Props> = ({ score }) => {
  const lowScoreColor = score > 30 ? 'warning' : 'error';
  const color = score > 60 ? 'success' : lowScoreColor;
  const specialCase = score === 'NaN';

  return (
    <Chip
      color={specialCase ? 'info' : color}
      label={specialCase ? '—' : `${score}%`}
      sx={{ width: '47px', '.MuiChip-label': { padding: 0 } }}
    />
  );
};
