import React, { FC, useMemo, useState } from 'react';
import { isAfter } from 'date-fns';
import { useTranslation } from 'react-i18next';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import {
  DateTimePicker,
  DateTimePickerSlotsComponentsProps,
} from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextFieldProps, Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { CalendarHeader } from 'components/CalendarHeader';
import { CalendarLayout } from 'components/CalendarLayout';
import { Languages } from 'enums/Languages.enum';
import { DayItem } from './DayItem';
import { DigitalClockItem } from './DigitalClockItem';

interface Props {
  trainingTimes?: [string, string][];
}

const CustomDatePickerOpen = ({ InputProps }: TextFieldProps) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
    }}
    ref={InputProps?.ref}
  >
    {InputProps?.endAdornment}
  </Box>
);

export const TrainingDataPicker: FC<Props> = ({ trainingTimes }) => {
  const { i18n, t } = useTranslation();
  const locale = i18n.language === Languages.EN ? enLocale : deLocale;
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const preparedTrainingTimes = useMemo(
    () =>
      (trainingTimes || []).map(([dateA, dateB]) =>
        isAfter(new Date(dateA), new Date(dateB))
          ? [new Date(dateB), new Date(dateA)]
          : [new Date(dateA), new Date(dateB)]
      ),
    [trainingTimes]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginTop: '16px',
      }}
    >
      <Typography variant="body2">
        {t('modelInformation.trainingTimes')}:
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
        <DateTimePicker
          slots={{
            textField: CustomDatePickerOpen,
            day: DayItem,
            digitalClockSectionItem: DigitalClockItem,
            calendarHeader: CalendarHeader,
            layout: CalendarLayout,
          }}
          slotProps={
            {
              day: {
                trainingTimes: preparedTrainingTimes,
              },
              digitalClockSectionItem: {
                trainingTimes: preparedTrainingTimes,
                selectedDate,
              },
            } as DateTimePickerSlotsComponentsProps<Date>
          }
          ampm={false}
          value={null}
          onChange={(date) => setSelectedDate(date)}
          sx={{
            paddingTop: '7px',
            paddingBottom: '7px',
          }}
        />
      </LocalizationProvider>
    </Box>
  );
};
