import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import Box from '@mui/material/Box';

import { AppRoutes } from 'enums/Routes.enum';

import { useAuth } from 'hooks/Auth/useAuth';

import { ReactComponent as DashboardFilled } from 'assets/icons/dashboard-filled.svg';
import { ReactComponent as DashboardOutlined } from 'assets/icons/dashboard-outlined.svg';
import { ReactComponent as ComponentOutlined } from 'assets/icons/detail-outlined.svg';
import { ReactComponent as MachineOutlined } from 'assets/icons/machine-outlined.svg';
import { ReactComponent as SensorIcon } from 'assets/icons/sensor-outlined.svg';
import { MachineList } from 'types/machine';
import { useQuery } from 'react-query';
import { machinesQueryKeys } from 'enums/MachinesQueryKeys.enum';
import { fetchAllMachinesParts } from 'services/API/machine';
import { APIError } from 'types/apiError';
import { STALE_DATA_TIME } from 'utils/constants';

const WrapperStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
};

const ItemStyles = {
  display: 'block',
  maxWidth: '200px',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '20px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  cursor: 'pointer',
};

const OtherItemStyles = {
  ...ItemStyles,
  cursor: 'auto',
};

const getNodeWithPath = (
  items: MachineList[],
  id: string
): {
  path: MachineList[];
  item: MachineList;
}[] => {
  const paths: {
    path: MachineList[];
    item: MachineList;
  }[] = [];
  items.forEach((item) => {
    if (item.id === id) {
      paths.push({ path: [item], item });
    } else {
      paths.push(
        ...getNodeWithPath(item.children, id).map((child) => ({
          path: [item, ...child.path],
          item: child.item,
        }))
      );
    }
  });
  return paths;
};

const getIcon = (item: MachineList, firstItem: boolean) => {
  if (item.is_sensor) {
    return <SensorIcon />;
  }
  if (firstItem) {
    return <MachineOutlined />;
  }
  return <ComponentOutlined />;
};

export const Breadcrumbs: FC = () => {
  const { userData } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();

  const isDashboardPage = pathname === AppRoutes.Dashboard;
  const isModelsPage = pathname === AppRoutes.ModelsList;

  const { data: allItems } = useQuery<MachineList[], APIError>(
    machinesQueryKeys.allMachines(),
    fetchAllMachinesParts,
    {
      retry: 0,
      staleTime: STALE_DATA_TIME,
    }
  );

  const nodeWithPath = useMemo(
    () => (allItems && id ? getNodeWithPath(allItems, id)[0] : undefined),
    [id, allItems]
  );

  if (isModelsPage) {
    return (
      <MuiBreadcrumbs aria-label="breadcrumb">
        <Box sx={WrapperStyles} onClick={() => navigate(AppRoutes.Dashboard)}>
          <DashboardOutlined />
          <Box sx={ItemStyles}>{t('dashboard')}</Box>
        </Box>
        <Box sx={WrapperStyles}>
          <MachineOutlined />
          <Box sx={OtherItemStyles}>
            {t('machines', { count: Number.MAX_SAFE_INTEGER })}
          </Box>
        </Box>
        <Box sx={WrapperStyles}>
          <ComponentOutlined />
          <Box sx={OtherItemStyles}>
            {t('components', { count: Number.MAX_SAFE_INTEGER })}
          </Box>
        </Box>
        <Box
          sx={{
            ...WrapperStyles,
            '& .cls-1': {
              fill: (theme) => theme.palette.custom.primaryFontColor,
            },
            // TODO: Remove when icon clippath
            '& .cls-2': {
              fill: '#FAFAFA',
            },
          }}
        >
          <SensorIcon />
          <Box
            sx={{
              ...OtherItemStyles,
              color: (theme) => theme.palette.custom.primaryFontColor,
            }}
          >
            {t('models')}
          </Box>
        </Box>
      </MuiBreadcrumbs>
    );
  }

  return (
    <MuiBreadcrumbs aria-label="breadcrumb">
      <Box
        sx={WrapperStyles}
        onClick={() => !isDashboardPage && navigate(AppRoutes.Dashboard)}
      >
        {isDashboardPage ? <DashboardFilled /> : <DashboardOutlined />}
        <Box
          sx={{
            ...ItemStyles,
            color: (theme) =>
              isDashboardPage
                ? theme.palette.custom.primaryFontColor
                : theme.palette.custom.secondaryFontColor,
            cursor: isDashboardPage ? 'auto' : 'pointer',
          }}
        >
          {userData?.tenant.config.dashboard || t('breadcrumbs.dashboard')}
        </Box>
      </Box>
      {nodeWithPath?.path.map((item, n) => {
        return (
          <Box
            key={item.id}
            onClick={() =>
              navigate(generatePath(AppRoutes.Detail, { id: item.id }))
            }
            sx={{
              ...WrapperStyles,
              ...(n === nodeWithPath.path.length - 1 && {
                color: (theme) => theme.palette.custom.primaryFontColor,
                cursor: 'auto',
                '& .cls-1': {
                  fill: (theme) => theme.palette.custom.primaryFontColor,
                },
                // TODO: Remove when icon clippath
                '& .cls-2': {
                  fill: '#FAFAFA',
                },
              }),
            }}
          >
            {getIcon(item, n === 0)}
            <Box sx={ItemStyles}>{item.label}</Box>
          </Box>
        );
      })}
    </MuiBreadcrumbs>
  );
};
