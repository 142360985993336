import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { toast } from 'react-toastify';
import { detailsQueryKeys } from 'enums/DetailsQueryKeys.enum';
import { DetailPath, fetchDetailPath } from 'services/API/detailPath';
import { APIError } from 'types/apiError';
import { STALE_DATA_TIME } from 'utils/constants';
import { getAPIErrorText } from 'utils/helpers';

interface Props {
  id?: string | null;
  enabled?: boolean;
  options?: Partial<UseQueryOptions<DetailPath, APIError>>;
}

type UseDetailPath = (props: Props) => UseQueryResult<DetailPath, APIError>;

export const useDetailPath: UseDetailPath = ({
  options,
  id,
  enabled = true,
}) => {
  const { t } = useTranslation();

  return useQuery<DetailPath, APIError>(
    detailsQueryKeys.detailPath(id!),
    () => fetchDetailPath(id!),
    {
      retry: 0,
      staleTime: STALE_DATA_TIME,
      enabled: !!id && enabled,
      onError(error) {
        toast.error(getAPIErrorText(error) || t('errors.defaultError'));
      },
      ...(options || {}),
    }
  );
};
