import { api } from 'services/API/client';
import { Detail } from 'types/detail';

export interface DetailPath {
  part: Detail;
  tree: Detail[];
}

export const fetchDetailPath = async (id: string): Promise<DetailPath> => {
  const {
    data: { data },
  } = await api.get(`/machines/${id}/details`);

  return data;
};
