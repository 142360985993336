import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Pie, PieChart } from 'recharts';

import Box from '@mui/material/Box';

import { TotalOverallStateData } from 'types/state';

import { ClockUTC } from 'components/ClockUTC';

interface OverallStatesChartProps {
  stateData: TotalOverallStateData[];
  renderDateRangeLabel?: ReactElement;
}

export const OverallStatesChart: FC<OverallStatesChartProps> = ({
  stateData,
  renderDateRangeLabel,
}) => {
  const { t } = useTranslation();

  if (!stateData) return null;

  const filteredStates = stateData
    .filter((state) => state?.value > 0)
    .sort((a, b) => b.value - a.value)
    .slice(0, 4);

  return (
    <Box
      sx={{
        width: '312px',
        height: 'fit-content',
        borderRadius: '4px',
        border: '1px solid',
        borderColor: (theme) => theme.palette.custom.borderColor,
        backgroundColor: (theme) => theme.palette.custom.white,
      }}
    >
      <Box
        sx={{
          padding: '6px 16px',
          fontSize: '14px',
          fontWeight: '500',
          lineHeight: '157%',
          borderBottom: '1px solid',
          borderColor: (theme) => theme.palette.custom.borderColor,
        }}
      >
        {t('charts.overallStatesTittle')}
      </Box>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <PieChart width={248} height={236}>
          <Pie
            data={filteredStates}
            dataKey="value"
            nameKey="label"
            cx="50%"
            cy="50%"
            innerRadius={63}
            outerRadius={80}
            startAngle={90}
            endAngle={-270}
          >
            {filteredStates.map((entry) => (
              <Cell
                key={`${entry.label}-${entry.value}`}
                fill={entry.hexcolor}
              />
            ))}
          </Pie>
        </PieChart>
        {renderDateRangeLabel ? (
          <Box
            sx={{
              fontWeight: '500',
              fontSize: '15px',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {renderDateRangeLabel}
          </Box>
        ) : (
          <ClockUTC />
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-around',
          marginBottom: '12px',
          height: '8px',
        }}
      >
        {filteredStates.map((entry) => (
          <Box
            key={`${entry.label}-${entry.hexcolor}`}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '12px',
            }}
          >
            <Box
              sx={{
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                backgroundColor: entry.hexcolor,
                marginRight: '4px',
              }}
            />
            {entry.label}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
