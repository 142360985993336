import React from 'react';
import { Link, Card, DataList, Grid, Heading, Spinner } from '@radix-ui/themes';
import { generatePath, Link as BrowserLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MachineList } from 'types/machine';
import { useQuery } from 'react-query';
import { APIError } from 'types/apiError';
import { machinesQueryKeys } from 'enums/MachinesQueryKeys.enum';
import { fetchAllMachinesParts } from 'services/API/machine';
import { STALE_DATA_TIME } from 'utils/constants';
import { AppRoutes } from 'enums/Routes.enum';

const getAllLeafNodesWithPath = (
  items: MachineList[]
): {
  path: MachineList[];
  item: MachineList;
}[] => {
  const paths: {
    path: MachineList[];
    item: MachineList;
  }[] = [];
  items.forEach((item) => {
    if (item.children.length === 0) {
      paths.push({ path: [item], item });
    } else {
      paths.push(
        ...getAllLeafNodesWithPath(item.children).map((child) => ({
          path: [item, ...child.path],
          item: child.item,
        }))
      );
    }
  });
  return paths;
};

export const ModelsPage = () => {
  const { t } = useTranslation();
  const { data: allItems, isLoading } = useQuery<MachineList[], APIError>(
    machinesQueryKeys.allMachines(),
    fetchAllMachinesParts,
    {
      retry: 0,
      staleTime: STALE_DATA_TIME,
    }
  );
  const models = getAllLeafNodesWithPath(allItems || []);
  return (
    <div style={{ paddingBottom: '12px' }}>
      <Heading>{t('models')}</Heading>
      {isLoading && <Spinner />}
      {!isLoading && (
        <Grid
          columns={{
            initial: '3',
            lg: '4',
            xl: '5',
          }}
          gap="3"
          mt="3"
        >
          {models?.map((model) => (
            <BrowserLink
              to={generatePath(AppRoutes.Sensor, { id: model.item.id })}
              key={model.item.id}
            >
              <Card>
                <Heading size="4">{model.item.label}</Heading>
                <DataList.Root my="2" size="1">
                  {model.path.map((parent: MachineList, n: number) => {
                    if (n === model.path.length - 1) return null;
                    return (
                      <DataList.Item key={parent.id}>
                        <DataList.Label>
                          {n === 0
                            ? t('machines', { count: 1 })
                            : `${t('components', { count: 1 })} ${n}`}
                        </DataList.Label>
                        <DataList.Value>
                          {
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <Link asChild>
                              <BrowserLink
                                to={generatePath(AppRoutes.Detail, {
                                  id: parent.id,
                                })}
                              >
                                {parent.label}
                              </BrowserLink>
                            </Link>
                          }
                        </DataList.Value>
                      </DataList.Item>
                    );
                  })}
                </DataList.Root>
              </Card>
            </BrowserLink>
          ))}
        </Grid>
      )}
    </div>
  );
};
