import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Box from '@mui/material/Box';

import { BasicTable } from 'components/BasicTable';
import { EmptyMessage } from 'components/EmptyMessage/EmptyMessage';
import { If } from 'components/If';

import { machinesQueryKeys } from 'enums/MachinesQueryKeys.enum';
import { detailsQueryKeys } from 'enums/DetailsQueryKeys.enum';
import { AppRoutes } from 'enums/Routes.enum';

import { fetchDetails } from 'services/API/detail';
import { MachinesFullData, fetchMachines } from 'services/API/machine';

import { useDetailPath } from 'hooks/DetailPath/useDetailPath';
import { SortingOrder, SortingType } from 'hooks/Table/useTableSorting';

import {
  STALE_DATA_TIME,
  REFRESH_MACHINES_TABLE_INTERVAL,
} from 'utils/constants';
import { getAPIErrorText } from 'utils/helpers';

import { APIError } from 'types/apiError';

import { columns } from './columns';

export const MostCriticalTable: FC<{ isMachinesList?: boolean }> = ({
  isMachinesList = false,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [navigateId, setNavigateId] = useState<null | string>(null);

  const options = {
    id: id || '',
    limit: 4,
    offset: 0,
    sort: SortingType.HEALTH,
    order: SortingOrder.ASC,
  };

  // fetch parts list
  const { data: partsList, isLoading: partsListIsLoading } = useQuery<
    MachinesFullData,
    APIError
  >(detailsQueryKeys.filteredDetails(options), () => fetchDetails(options), {
    retry: 0,
    refetchInterval: REFRESH_MACHINES_TABLE_INTERVAL,
    staleTime: STALE_DATA_TIME,
    enabled: !!id,
    onError(error) {
      toast.error(getAPIErrorText(error) || t('errors.defaultError'));
    },
  });

  // fetch machines list
  const { data: machinesList, isLoading: machinesListIsLoading } = useQuery<
    MachinesFullData,
    APIError
  >(machinesQueryKeys.filteredMachines(options), () => fetchMachines(options), {
    retry: 0,
    refetchInterval: REFRESH_MACHINES_TABLE_INTERVAL,
    staleTime: STALE_DATA_TIME,
    enabled: isMachinesList,
    onError(error) {
      toast.error(getAPIErrorText(error) || t('errors.defaultError'));
    },
  });

  useDetailPath({
    id: navigateId,
    options: {
      select: (data) => {
        const route = data.part.is_sensor ? AppRoutes.Sensor : AppRoutes.Detail;
        setNavigateId(null);

        setTimeout(() => {
          navigate(generatePath(route, { id: data.part.id }));
        }, 0);

        return data;
      },
    },
  });

  return (
    <Box width="100%">
      <BasicTable
        columns={columns}
        data={machinesList?.data || partsList?.data || []}
        onClickByRow={(id) => setNavigateId(id)}
        loading={machinesListIsLoading}
      />
      <If
        condition={
          (!partsListIsLoading && !partsList?.data.length && !isMachinesList) ||
          (!machinesListIsLoading &&
            !machinesList?.data.length &&
            isMachinesList)
        }
      >
        <EmptyMessage message={t('emptyMessage.details')} />
      </If>
    </Box>
  );
};
