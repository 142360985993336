import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

import Box from '@mui/material/Box';

import { If } from 'components/If';

import {
  MAX_Y_AXIS,
  MIN_Y_AXIS,
  ONE_DAY_IN_MS,
  REFRESH_GENERAL_OVERVIEW_DEFAULT,
  STALE_DATA_TIME,
} from 'utils/constants';
import { getAPIErrorText } from 'utils/helpers';

import {
  fetchMachineEvents,
  fetchMachineHealth,
  TimeRangeQuery,
} from 'services/API/machine';

import { machinesQueryKeys } from 'enums/MachinesQueryKeys.enum';
import { Languages } from 'enums/Languages.enum';

import { useGeneralChartData } from 'hooks/ChartData/useGeneralChartData';

import { APIError } from 'types/apiError';
import { DateRangeType, MachineHealthData } from 'types/machine';
import { CustomerEvent } from 'types/event';

import { theme } from 'styles/theme';
import { format, getTime, isAfter, parse, subDays } from 'date-fns';
import enLocale from 'date-fns/locale/en-US';
import deLocale from 'date-fns/locale/de';

const timeRangeQuery: TimeRangeQuery = {
  start: new Date(Date.now() - ONE_DAY_IN_MS).toISOString(),
  end: new Date(Date.now()).toISOString(),
};

type TableLightChartProps = {
  dateRange: DateRangeType;
  itemId?: string;
  isShowTicks?: boolean;
};

const CHART_TICKS = [
  '00:00',
  '02:00',
  '04:00',
  '06:00',
  '08:00',
  '10:00',
  '12:00',
  '14:00',
  '16:00',
  '18:00',
  '20:00',
  '22:00',
];

const getTickTimeStamp = () =>
  CHART_TICKS.map((tick: string) => {
    const tickDate = parse(tick, 'HH:mm', new Date());
    return isAfter(new Date(), tickDate)
      ? getTime(tickDate)
      : getTime(subDays(tickDate, 1));
  }).sort();

export const DashboardTableChart: FC<TableLightChartProps> = ({
  dateRange,
  itemId,
  isShowTicks,
}) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const locale = i18n.language === Languages.EN ? enLocale : deLocale;
  const options = {
    id: itemId || '',
    timeRangeQuery,
  };

  const { data: healthStatusData, isLoading: isChartPointsLoading } = useQuery<
    MachineHealthData[],
    APIError
  >(
    machinesQueryKeys.filteredMachineHealth(options),
    () => fetchMachineHealth(options),
    {
      retry: 0,
      staleTime: STALE_DATA_TIME,
      refetchInterval: REFRESH_GENERAL_OVERVIEW_DEFAULT,
      enabled: !!itemId,
      onError(error) {
        toast.error(getAPIErrorText(error) || t('errors.defaultError'));
      },
    }
  );

  const ticks = getTickTimeStamp();

  const { data: eventsData, isLoading: isChartEventsLoading } = useQuery<
    CustomerEvent[],
    APIError
  >(
    machinesQueryKeys.filteredMachineEvents(options),
    () => fetchMachineEvents(options),
    {
      retry: 0,
      staleTime: STALE_DATA_TIME,
      refetchInterval: REFRESH_GENERAL_OVERVIEW_DEFAULT,
      enabled: !!itemId,
      onError(error) {
        toast.error(getAPIErrorText(error) || t('errors.defaultError'));
      },
    }
  );

  const generalChartData = useGeneralChartData(
    dateRange,
    healthStatusData,
    eventsData
  );

  return (
    <Box>
      <If condition={!isChartPointsLoading && !isChartEventsLoading}>
        <ResponsiveContainer
          width="99%"
          height={isShowTicks ? 84 : 64}
          maxHeight={64}
        >
          <ComposedChart
            data={generalChartData}
            margin={{
              top: 1,
              right: 1,
              left: -40,
              bottom: isShowTicks ? -10 : -29,
            }}
          >
            <XAxis
              dataKey="date"
              axisLine={false}
              tickLine={false}
              tickCount={12}
              ticks={ticks}
              type="number"
              scale="time"
              domain={['auto', 'auto']}
              minTickGap={0}
              tickSize={1}
              tickFormatter={(tick) =>
                format(new Date(tick), 'HH:mm', { locale })
              }
            />
            <YAxis
              dataKey="health"
              axisLine={false}
              tickLine={false}
              domain={[MIN_Y_AXIS, MAX_Y_AXIS]}
              tick={false}
            />
            <CartesianGrid opacity={0.7} horizontal={false} />
            {eventsData?.map((item) => {
              return (
                <Area
                  type="step"
                  dataKey={item.id}
                  fill={item.type.hexcolor}
                  fillOpacity={0.1}
                  strokeWidth={0}
                  activeDot={false}
                  key={item.id}
                  id={item.id}
                  isAnimationActive={false}
                />
              );
            })}
            <Line
              type="linear"
              dataKey="health"
              stroke={theme.lightBlue}
              fillOpacity={0}
              strokeWidth={2}
              activeDot={false}
              dot={false}
              isAnimationActive={false}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </If>
    </Box>
  );
};
