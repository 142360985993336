import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';

import { Languages } from 'enums/Languages.enum';

// From the Recharts documentation: tick?:((props: any) => ReactElement<SVGElement>)
// eslint-disable-next-line
export const CustomXAxisTick: FC<any> = (props) => {
  const { x, y, payload, fill, isLongDateFormat } = props;
  const { i18n } = useTranslation();
  const locale = i18n.language === Languages.EN ? enLocale : deLocale;

  if (!payload) return null;

  const data = format(
    new Date(payload.value),
    isLongDateFormat ? 'EEE do MMM' : 'HH:mm iii',
    {
      locale,
    }
  ).split(' ');

  return (
    <text x={x} y={y} dy="0" textAnchor="middle" fill={fill}>
      <tspan x={x} dy="1.2em">
        {data[0]}
      </tspan>
      <tspan x={x} dy="1.2em">
        {data[1]} {data[2]}
      </tspan>
    </text>
  );
};
