import React, { FC, useEffect } from 'react';
import { Box, CircularProgress, Slider, Typography } from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import {
  SensorSensitivityResponse,
  SensorSensitivityUpdateFormData,
} from 'types/sensor';
import { APIError } from 'types/apiError';
import { sensorQueryKeys } from 'enums/SensorQueryKeys.enum';
import {
  fetchSensorSensitivityData,
  updateSensorSensitivity,
} from 'services/API/sensor';
import { STALE_DATA_TIME } from 'utils/constants';
import { toast } from 'react-toastify';
import { getAPIErrorText } from 'utils/helpers';
import { useTranslation } from 'react-i18next';
import { If } from 'components/If';
import { format } from 'date-fns';
import { queryClient } from 'index';
import { machinesQueryKeys } from 'enums/MachinesQueryKeys.enum';
import { SensorChartSettingsButton } from 'views/ChartView/SensorChart/SensorChartSettingsButton';
import { TrainingDataPicker } from 'components/TrainingDataPicker';

const spinnerBoxStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
};

interface Props {
  id: string;
}

export const SensorSensitivitySettings: FC<Props> = ({ id }) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(1);

  const { mutate: onSubmit } = useMutation<
    SensorSensitivityResponse,
    APIError,
    SensorSensitivityUpdateFormData
  >(updateSensorSensitivity, {
    onSuccess() {
      queryClient.invalidateQueries(machinesQueryKeys.machineHealth);
    },
    onError(error) {
      toast.error(getAPIErrorText(error) || t('errors.defaultError'));
    },
  });

  const {
    status,
    data: sensitivityData,
    isLoading,
  } = useQuery<SensorSensitivityResponse, APIError>(
    sensorQueryKeys.sensitivityPath(id),
    () => fetchSensorSensitivityData(id),
    {
      retry: 0,
      staleTime: STALE_DATA_TIME,
      onError(error) {
        toast.error(getAPIErrorText(error) || t('errors.defaultError'));
      },
    }
  );

  useEffect(() => {
    if (status === 'success') {
      setValue(sensitivityData?.sensitivity || 1);
    }
  }, [status, sensitivityData]);

  const handleChange = (
    event: React.SyntheticEvent | Event,
    value: number | number[]
  ) => {
    setValue(value as number);
  };

  const handleChangeCommitted = () => {
    onSubmit({
      id,
      sensitivity: value as number,
    });
  };

  const marks = [
    {
      value: 1,
      label: t('modelInformation.low'),
    },
    ...Array.from({ length: 8 }).map((_, i) => ({
      value: i + 2,
    })),
    {
      value: 10,
      label: t('modelInformation.high'),
    },
  ];

  return (
    <SensorChartSettingsButton>
      <Box
        sx={{
          width: '300px',
          padding: '16px 32px',
          height: 'fit-content',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            marginBottom: '16px',
          }}
        >
          {t('modelInformation.sensitivity')}
        </Typography>

        <If condition={isLoading}>
          <Box sx={spinnerBoxStyles}>
            <CircularProgress color="primary" size={50} />
          </Box>
        </If>

        <If condition={!isLoading}>
          <Slider
            aria-label="Sensitivity"
            defaultValue={1}
            value={typeof value === 'number' ? value : 1}
            getAriaValueText={(value) => `${value}`}
            valueLabelDisplay="auto"
            step={1}
            marks={marks}
            min={1}
            max={10}
            onChange={handleChange}
            onChangeCommitted={handleChangeCommitted}
          />

          <Typography
            variant="body2"
            sx={{
              marginTop: '16px',
            }}
          >
            {t('modelInformation.lastTrained')}:{' '}
            {sensitivityData?.last_training
              ? format(
                  new Date(sensitivityData.last_training),
                  'd LLL yyyy HH:mm'
                )
              : '-'}
          </Typography>
        </If>
        <TrainingDataPicker trainingTimes={sensitivityData?.training_times} />
      </Box>
    </SensorChartSettingsButton>
  );
};
