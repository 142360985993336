import { Button, Popover } from '@mui/material';
import React, { useState, MouseEvent, FC } from 'react';

import SettingsIcon from '@mui/icons-material/Settings';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

interface Props {
  children: React.ReactNode;
}

export const SensorChartSettingsButton: FC<Props> = ({ children }) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);
  const id = isOpen ? 'settings-popover' : undefined;

  return (
    <>
      <Button
        aria-label="back"
        sx={{
          marginRight: 0,
          marginLeft: '0',
          p: '0 12px',
        }}
        variant="outlined"
        color="secondary"
        aria-describedby={id}
        onClick={isOpen ? handleClose : handleClick}
        startIcon={<SettingsIcon />}
        endIcon={isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      >
        {t('charts.settings')}
      </Button>

      <Popover
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {children}
      </Popover>
    </>
  );
};
